import { setupLibraries } from "./common_head";
import { loadClientConstants } from "./clientConstants";
import { loadIdentityService } from "./googleIdentity";

const clientConstants = loadClientConstants();
window.YNAB_CLIENT_CONSTANTS = clientConstants;
// Copy PRODUCTION to the top level, to make parity with the account widget easier
window.PRODUCTION = clientConstants.PRODUCTION;

setupLibraries(clientConstants);

if (window.YNAB_CLIENT_CONSTANTS.GOOGLE_CLIENT_ID) {
    loadIdentityService({ googleClientId: window.YNAB_CLIENT_CONSTANTS.GOOGLE_CLIENT_ID });
}
